@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600&display=swap");
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  min-height: 0;
  min-width: 0;
}

body {
  background: url(assets/images/home-bg.png) no-repeat right center;
  width: 100%;
  height: 100%;
  background-position-x: -32rem;
  background-position-y: top;
  @apply bg-primary-blue;
}

@layer components {
  /*VARS */
  :root {
    --axis-bold: "Axis Bold";
    --axis-normal: "Axis";
    --futura-light: "Futura TB light";
  }

  .font-axis-bold {
    font-family: var(--axis-bold);
  }

  .font-axis {
    font-family: var(--axis-normal);
  }

  /*TYPOGRAPHY*/
  @font-face {
    font-family: "Axis Bold";
    src: url("assets/fonts/axis/Axis_Extrabold.otf");
  }
  @font-face {
    font-family: "Axis";
    font-style: normal;
    font-weight: normal;
    src: local("Axis"), url("assets/fonts/axis/Axis.woff") format("woff");
  }

  @font-face {
    font-family: "Futura TB light";
    src: url("./assets/fonts/futurabt/futura-light-bt.ttf");
  }
  .font-futura-light {
    font-family: var(--futura-light);
  }
  .Collapsible > .Collapsible__trigger {
    font-family: var(--axis-bold);
    @apply block w-full p-4;
  }

  /*CUSTOM UTILITIES*/
  .flex-column {
    @apply flex flex-col;
  }

  .row {
    @apply flex flex-row;
  }

  /*NAVBAR COMPONENTS*/
  .responsive-flex {
    @apply flex md:flex-row justify-between items-center sm:gap-4 gap-1;
  }

  /*CUSTOM BUTTONS AND CTA*/
  .cta {
    @apply cursor-pointer;
  }
  .blue-gradient-btn {
    border-radius: 10px;
    background-image: linear-gradient(125deg, #094fc3 0%, #1b1363 100%);
    @apply text-white py-4 px-6 my-5;
  }
  .yellow-gradient-btn {
    border-radius: 10px;
    background-image: linear-gradient(90deg, #fdaf23 0%, #f5de1f 100%);
    @apply text-black py-4 px-6 my-5;
  }

  .collapse-btn-bg {
    background: url(assets/images/collapse-sidebar-bg.png) no-repeat center
      center;
    background-size: 100%;
    @apply absolute bottom-0 -right-10;
  }

  .shape-polygon {
    clip-path: polygon(10% 0%, 100% 0%, 100% 100%, 0% 100%);
  }

  .cta-bg-soda {
    @apply bg-primary-yellow;
    background-image: linear-gradient(358deg, #fbb034 15%, #ffdd00 74%);
  }

  .header-title {
    font-family: var(--axis-bold);
    @apply text-white;
  }

  .logo-brand {
    @apply mx-4 sm:my-3 my-0;
  }

  /*CUSTOMISE LIB COMPONENTS STYLES*/
  .Collapsible {
    @apply p-0 pb-0 border-b-2 border-gray-400 relative;
  }

  .Collapsible .is-closed::after {
    content: url("assets/images/chevron-down-blue.svg");
    @apply p-4 absolute right-0 top-0;
  }

  .Collapsible .is-open::after {
    content: url("assets/images/chevron-top-blue.svg");
    @apply p-4 absolute right-0 top-0;
  }

  .Collapsible .Collapsible__contentInner {
    @apply px-4;
  }

  .Collapsible .Collapsible__contentInner .collapsible-content-container {
    @apply mb-4;
  }

  /*ADS CARDS*/
  .border-2-dee9ff {
    border: 2px solid #dee9ff;
  }

  /*PAGINATION*/
  .pagination {
    @apply flex items-center justify-center gap-4;
  }

  .pagination > li {
    @apply min-w-[32px] h-[32px] rounded-full p-0 flex items-center justify-center text-[#919191];
  }

  .pagination > li a {
    display: inline-block;
  }

  .pagination > .selected {
    @apply my-5 rounded-lg bg-primary-blue text-white cursor-default font-montserrat font-bold;
  }

  .pagination > .disabled {
    @apply cursor-default;
    color: #d1d1d1;
  }

  .pagination > .disabled a,
  .pagination > .selected a {
    @apply cursor-default p-0 text-sm;
  }

  .filer-container {
    @apply rounded-tl-lg  min-h-screen h-full w-full absolute sm:static z-10;
    max-width: 300px;
  }
  .filer-container,
  .Collapsible {
    @apply bg-light-gray;
  }

  .filter-container,
  .filter-header {
    @apply block sm:flex items-baseline justify-between px-4 py-5;
    border-bottom: 1px solid #c0c0c0;
  }

  .filter-header h1 {
    @apply text-3xl text-silver uppercase font-axis-bold;
  }

  .filter-container,
  .btn-reset-filters {
    @apply text-red-600 text-xs whitespace-nowrap normal-case font-montserrat font-bold;
  }

  .filter-container,
  .Collapsible {
    @apply font-axis-bold text-sm uppercase;
    border-bottom: 1px solid #c0c0c0;
  }

  .filter-container,
  .Collapsible:last-child {
    @apply border-b-0;
  }

  .filter-container,
  .Collapsible .collapsible-content-container {
    @apply flex items-center text-xs;
    grid-template-columns: 1fr 3fr;
  }

  @media (min-width: 1024px) {
    .filter-container,
    .Collapsible .collapsible-content-container {
      grid-template-columns: 1fr 5fr;
    }
  }
  .filter-container,
  .Collapsible .collapsible-content-container label {
    @apply uppercase text-gray-999999;
  }

  .filter-container,
  .Collapsible .collapsible-content-container input,
  .Collapsible .collapsible-content-container select {
    @apply px-2 py-4 font-montserrat font-semibold w-full;
    border-radius: 3px;
    background-color: #f4f7fa;
    border: 1px solid #cecece;
  }

  .filter-hidden {
    @apply grid-cols-1;
  }

  .sort select {
    @apply px-2 py-4 font-montserrat font-semibold w-full;
    border-radius: 10px;
    border: 1px solid #dee1e8;
    color: #c1c1c1;
  }

  /*HERO SLIDER */
  .slider-card {
    background: #dddddd;
    @apply px-10 py-5 text-sm font-montserrat font-semibold text-center flex justify-center flex-col gap-2 items-center uppercase w-full h-full;
    color: #666666;
  }

  .ad-card {
    @apply p-4 bg-white;
  }

  .ad-card-white {
    @apply bg-white;
  }

  .ad-card .ad-card-head {
    @apply bg-black p-3 text-white font-normal font-montserrat italic;
  }

  .ad-card-title,
  .price-details {
    @apply pb-3;
  }

  .ad-card-container {
    border: 1px solid #dee9ff;
    border-radius: 0 0 20px 20px;
  }

  .price-details .price {
    letter-spacing: -1px;
    color: #0b48b8;
    text-align: center;
    @apply font-axis-bold;
  }

  .test-drive-btn {
    @apply py-5 px-10 mb-2 text-white font-montserrat font-semibold block w-full;
    border-radius: 4px;
    background-color: #33cc66;
  }

  .test-drive-btn,
  .offre-btn,
  .blue-light-btn {
    @apply hover:shadow-2xl cursor-pointer;
  }

  .btn-form {
    @apply rounded text-white p-3 bg-black font-semibold;
  }

  .min-h-250 {
    min-height: 250px;
    max-height: 250px;
  }

  .text-custom-blue {
    color: #0f3ca4;
  }
}

/*CAROUSEL */

/*RESET THE DEFAULT STYLE */
.slick-next,
.slick-prev {
  color: transparent;
  @apply z-10 bg-no-repeat;
}

.slick-prev {
  transform: translateY(-50%);
}

.slick-next:focus,
.slick-prev:focus {
  outline: none;
  outline-offset: 0;
}

.slick-next::before,
.slick-prev::before {
  content: "";
}

.slick-next,
.slick-prev {
  height: 40px;
  width: 40px;
  opacity: 0.7;
}

.slick-prev,
.slick-prev:hover {
  left: 20px;
  background: url(./assets/images/slider-button.svg) center center/contain
    no-repeat;
  transform: rotateY(180deg);
}

.slick-next,
.slick-next:hover {
  background: url(./assets/images/slider-button.svg) center center/contain
    no-repeat;
  right: 20px;
}
.slick-prev:hover,
.slick-next:hover {
  opacity: 1;
}
.slick-slide img {
  display: inline-block;
}
.special-table {
  @apply shadow-sm text-left font-montserrat grid grid-cols-1 sm:grid-cols-2 gap-x-10;
}
.special-table tr {
  @apply border-b-2 flex justify-between gap-x-2 items-center;
}
.special-table td,
.special-table th {
  @apply py-2;
}
@media screen and (min-width: 1024px) {
  .custom-navigate-arrow-ex-1 .slick-list {
    max-width: 1024px;
    display: block;
    margin: 0 auto;
  }
}

.bg-media-car-people {
  /*background: url(/public/media-car-people-bg.png) center center/cover;*/
  background: #e9e9e9;
}
.custom-footer-v1 {
  background-image: linear-gradient(125deg, #1b1363 0%, #094fc3 100%);
}

.newsletter-input {
  @apply rounded-l-lg p-4 mr-0 text-white font-futura-light w-full;
  background-color: rgba(255, 255, 255, 0.10196078431372549);
}
@media screen and (min-width: 1200px) {
  .newsletter-input {
    width: 361px;
  }
}
.newsletter-input:focus {
  outline: none;
}
.newsletter-button {
  background-color: #006cff;
  @apply py-[.5rem] px-[.7rem] rounded-tr-lg rounded-br-lg sm:whitespace-nowrap whitespace-pre-wrap text-xs sm:w-auto w-full;
}
.custom-footer-v1 {
  background-image: linear-gradient(125deg, #1b1363 0%, #094fc3 100%);
}
.bg-media-car-people {
  background: #e9e9e9;
}

.latest-products {
  background: #d8ebfc;
  margin: 0 20px;
}

.latest-products .next,
.latest-products .prev {
  position: absolute;
  background-size: 100%;
  top: 50%;
  text-indent: -10000px;
  transform: translateY(-50%);
}
.latest-products .prev {
  background: url(./assets/images/prev.svg) center center no-repeat;
  background-size: 100%;
  left: 0;
  top: 50%;
}

.latest-products .next {
  background: url(./assets/images/next.svg) center center no-repeat;
  background-size: 100%;
  right: 0;
  top: 50%;
}
.lv-nav-buttons-4 {
  width: 40px;
  height: 80px;
}
.lv-nav-buttons-6 {
  width: 60px;
  height: 100px;
}
.lv-nav-buttons-8 {
  width: 80px;
  height: 150px;
}
@media screen and (min-width: 1024px) {
  .custom-navigate-arrow-ex-1 .slick-list {
    max-width: 1024px;
    display: block;
    margin: 0 auto;
  }
}

.table-heading {
  @apply border-b font-medium p-4 pl-8 pt-0 pb-3 text-slate-400 text-left;
}

.table-cell {
  @apply border-b border-slate-100 p-4 pl-8 text-slate-500;
}

.green-button {
  @apply bg-green-600 text-white py-3 px-5 rounded-lg;
}

.blue-button {
  @apply bg-blue-600 text-white py-3 px-5 rounded-lg;
}

.blue-light-btn {
  @apply bg-[#d8ebfc] py-5 px-10 mb-2 text-white font-montserrat font-semibold block w-full text-[#10399f] rounded-[4px];
}
.lr-blur-bg {
  @apply absolute -z-[1] top-0 left-0 right-0 bottom-0 blur-sm bg-no-repeat bg-center bg-cover;
}

.hr-sect {
  @apply flex gap-x-4 basis-[100%] items-center text-black my-[8px] capitalize font-semibold;
}
.hr-sect:before,
.hr-sect:after {
  content: "";
  flex-grow: 1;
  background: #d3d5d6;
  height: 2px;
  font-size: 0;
  line-height: 0;
}

/* CREDIT COUNTER */
.credit__counter__container {
  @apply flex justify-center mx-auto  border-[1px] border-[#c1c1c1] rounded-[10px] items-center max-w-max text-center;
}

.credit__counter__container input {
  @apply text-center;
}

.counter__btn {
  @apply cursor-pointer p-5 border-[#c1c1c1];
}
.counter__btn:nth-child(1) {
  @apply border-r-[1px];
}

.counter__btn:nth-child(3) {
  @apply border-l-[1px];
}

/*Button*/

.btn-rounded {
    @apply px-10 py-3 rounded;
}


.collapsible-content-container {
    @apply gap-4;
}

@media (max-width: 767px) {
    .pagination>li {
        min-width:fit-content;
    }
    .pagination {
        overflow-x: scroll;
        justify-content:start;
    }

}

.swiper-wrapper {
  @apply items-center ;
}

.swiper-container {
  width: 480px;
}

.swiper-slide > img {
  @apply object-none block mx-auto;
}

@media screen and (min-width: 640px) {
  .swiper-container {
    width: 640px;
  }
}

@media screen and (min-width: 768px) {
  .swiper-container {
    width: 768px;
  }
}